@font-face {
  font-family: ALS;
  src: url("../public/fonts/ALS_Hauss_Regular_1.003.otf") format("opentype");
}

@font-face {
  font-family: ALS;
  font-weight: 700;
  src: url("../public/fonts/ALS_Hauss_Bold_1.003.otf") format("opentype");
}

@font-face {
  font-family: ALS;
  font-weight: 550;
  src: url("../public/fonts/ALS_Hauss_Medium_1.003.otf") format("opentype");
}


body {
  background: url('./img/bg.jpg');
  background-position: top center;
  background-repeat: repeat-y;
  background-size: 1920px auto;
  font-family: 'ALS', roboto;
  margin: 0;
  padding: 0;

  color: #FFFFFF;

  font-size: 24px;
  line-height: 36px;
}

h1 {
  font-weight: 550;
  font-size: 64px;
  line-height: 1em;
}

h2 {
  font-size: 64px;
  font-weight: 550;
  line-height: 64px;
}

header {
  display: flex;
  align-content: flex-end;
  justify-content: flex-end;
  margin-top: 54px;
  grid-gap: 32px;
}

a {
  text-decoration: none;
}

.container {
  max-width: 1110px;
  margin: auto;
}

.block {
  &-1 {
    margin-top: 106px;
  }

  &-2 {
    margin-top: 70px;
  }

  &-3 {
    margin-top: 110px;
  }

  &-4 {
    margin-top: 108px;
    display: flex;
  }

  &-5 {
    max-width: 1280px;
    margin: 97px auto;
    background: linear-gradient(188.58deg, rgba(34, 152, 255, 0.5) 3.81%, rgba(10, 25, 65, 0.1) 122.67%);
    border-radius: 32px;
    padding: 60px 85px 60px 85px;
    box-sizing: border-box;

    h2 {
      margin-top: 0px;
    }

    .cols {
      justify-content: space-between;
      grid-gap: unset
    }
  }

}

.cols {
  display: flex;
  grid-gap: 32px;
}

.img {
  border-radius: 32px;
}

.logo_small {
  width: 26px;
  height: 26px;
}

.text {
  min-width: 445px;

  p {
    margin-top: 0;
  }
}

.img-block {
  width: 100%;

  .title {
    display: flex;
    align-items: center;

    font-size: 20px;
    line-height: 30px;
    margin-top: 16px;

    &_left {
      justify-content: flex-start;

      &:before {
        margin-left: -30px;
        background: linear-gradient(74deg, rgba(7, 26, 102, 0.7) 9.65%, rgba(176, 233, 255, 0.05) 57.42%, rgba(0, 2, 149, 0.03) 84.41%);
      }
    }

    &_right {
      justify-content: flex-end;

      &:before {
        background: linear-gradient(239.88deg, rgba(7, 26, 102, 0.7) 9.65%, rgba(176, 233, 255, 0.05) 57.42%, rgba(0, 2, 149, 0.03) 84.41%);
        margin-right: -30px;
      }
    }

    .logo_small {
      margin-right: 16px;
    }

    &:before {
      content: '';

      width: 636px;
      height: 350px;

      margin-top: -266px;

      position: absolute;
      border-radius: 32px;
      z-index: -1;
    }
  }
}

.block-btn {
  margin-top: 46px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
}

.btn {
  padding: 15px 26px 15px 26px;
  border-radius: 16px;
  border: 0;
  background: linear-gradient(82.65deg, rgba(7, 13, 185, 0.5) -3.6%, rgba(114, 203, 254, 0.5) 100.48%);
  color: #FFFFFF;
  cursor: pointer;
  font-size: 26px;
}

.platforms {
  display: flex;
  grid-gap: 80px;
  width: 100%;

  .platform {
    text-align: center;
    width: 33.3%;

    .title {
      margin-bottom: 20px;
      white-space: none;
      text-wrap: nowrap;

      &:not(.active) {
        color: #77787B;
      }
    }

    .logo {
      margin-bottom: 36px;
    }

    .action span {
      font-size: 20px;
      line-height: 30px;
      color: #77787B;
    }
  }
}

.qr {
  &-img {
    width: 133px;
    height: 133px;

    border-radius: 16px;
  }

  &-data {
    margin-top: 64px;
    display: flex;
    align-items: center;
  }

  &-info {
    margin-left: 20px;
  }


  &-text {
    margin-bottom: 36px;
  }
}

.form {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  width: 566px;
  justify-content: flex-end;
  align-items: center;
  margin-left: 90px;

  input,
  textarea {
    font-size: 20px;
    min-width: 566px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 15px;
    border: 0;
    font-family: 'ALS', roboto;
    resize: none;
  }

  input {
    line-height: 56px;
    padding: 0 40px;
  }

  textarea {
    height: 122px;
    padding: 18px 40px;
  }

  .btn {
    margin-top: 10px;
    width: 185px;
  }
}

.success {
  margin-top: 60px;
  color: #0f9c00;
  max-width: 500px;
  text-align: center;
}

.success p {

  width: 100px;
  text-align: center;
  font-size: 36px;
  line-height: 100px;
  border: 1px solid;
  border-radius: 200px;
  margin: 32px auto;

}